import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import * as S from "../styles/mesagePage/styles";

const NotFoundPage = () => {
  return (
    <Layout>
      <S.PageWrapper>
        <div className="container">
          <h1>404</h1>
          <p>Não encontramos o que você está procurando</p>
          <Link to="/">Voltar</Link>
        </div>
      </S.PageWrapper>
    </Layout>
  );
};

export default NotFoundPage;
